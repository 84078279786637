import { Component, OnInit } from '@angular/core';
import {
  Observable,
  take,
  tap,
} from 'rxjs';
import { AuthService } from 'src/app/authentication/auth.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {
  currentData$ = this.auth.currentData$.pipe(take(1));

  ngOnInit(): void {}

  formatCid(n: number, nationality: string): string {
    const prefix = 'V-';
    const nStr = String(n);
    let s0: string, s1: string, s2: string;

    if (n < 1000000) {
      s0 = nStr.substring(0, 3);
      s1 = nStr.substring(3, 6);
      return `${prefix}${s0}.${s1}`;
    } else if (n >= 10000000) {
      s0 = nStr.substring(0, 2);
      s1 = nStr.substring(2, 5);
      s2 = nStr.substring(5, 8);
    } else {
      s0 = nStr.substring(0, 1);
      s1 = nStr.substring(1, 4);
      s2 = nStr.substring(4, 7);
    }
    return `${prefix}${s0}.${s1}.${s2}`;
  }

  settings$ = this.settingsService.getSettings();
  constants$ = this.settingsService.getConstants();

  constructor(
    private auth: AuthService,
    private settingsService: SettingsService,
    private userService: UserService
  ) {}
}
