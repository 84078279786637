import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  AppstoreOutline,
  ArrowLeftOutline,
  BlockOutline,
  CalendarOutline,
  DashboardOutline,
  DeleteFill,
  DeleteOutline,
  EditOutline,
  FileExcelOutline,
  FileImageOutline,
  FileSearchOutline,
  FormOutline,
  FullscreenOutline,
  HeartFill,
  HeartOutline,
  HomeOutline,
  InboxOutline,
  InfoCircleOutline,
  LikeOutline,
  LinkOutline,
  LogoutOutline,
  MenuFoldOutline,
  MenuOutline,
  MenuUnfoldOutline,
  MessageOutline,
  NotificationFill,
  NotificationOutline,
  OrderedListOutline,
  PictureOutline,
  SettingFill,
  SettingOutline,
  SolutionOutline,
  UserOutline,
} from '@ant-design/icons-angular/icons';

const icons = [
  SolutionOutline,
  AppstoreOutline,
  BlockOutline,
  InboxOutline,
  ArrowLeftOutline,
  MenuFoldOutline,
  HeartFill,
  SettingFill,
  HeartOutline,
  FullscreenOutline,
  FileSearchOutline,
  LikeOutline,
  OrderedListOutline,
  FileExcelOutline,
  CalendarOutline,
  PictureOutline,
  NotificationFill,
  NotificationOutline,
  MenuOutline,
  HomeOutline,
  InfoCircleOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  FormOutline,
  SettingOutline,
  DeleteOutline,
  FileImageOutline,
  DeleteFill,
  HeartFill,
  LogoutOutline,
  EditOutline,
  MessageOutline,
  LinkOutline,
  UserOutline,
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule {}
