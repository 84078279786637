<ng-container
  *ngIf="{
    currentData: currentData$ | async,
  } as state"
>
  <section class="flex flex-col" *ngIf="state.currentData">
    <!-- Back Button -->
    <div class="absolute top-4 left-4">
      <button class="p-2 rounded-full bg-gray-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-gray-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
    </div>

    <!-- Main Content -->
    <div class="flex-1 flex flex-col items-center justify-center p-6 gap-6">
      <!-- Profile Section -->
      <div class="flex flex-col items-center gap-3">
        <div class="relative">
          <img
            *ngIf="state.currentData?.user?.profilePhoto"
            [src]="state.currentData?.user?.profilePhoto"
            class="w-20 h-20 rounded-full object-cover border-2 border-gray-100"
            alt="Foto de perfil"
          />
        </div>
        <div class="text-center">
          <h2 class="text-xl font-semibold text-gray-800">
            {{ state.currentData?.user?.fname }}
            {{ state.currentData?.user?.lname }}
          </h2>
          <p class="text-sm text-gray-500 mt-1">
            {{ state.currentData?.user?.cid | number : "1.0-0" }}
          </p>
          <p class="text-sm font-medium text-emerald-600">
            Miembro
            {{
              state.currentData.user?.isShareOwner ? "Propietario" : "Familiar"
            }}
          </p>
        </div>
      </div>

      <!-- Stats Section -->
      <div class="flex gap-4 w-full max-w-xs">
        <div class="flex-1 bg-gray-50 p-3 rounded-xl text-center">
          <p class="text-sm text-gray-500">Acción</p>
          <p class="text-lg font-semibold text-gray-800">
            {{ state.currentData?.share?.shareNumber }}
          </p>
        </div>
        <div class="flex-1 bg-gray-50 p-3 rounded-xl text-center">
          <p class="text-sm text-gray-500">Estado</p>
          <p class="text-lg font-semibold text-emerald-600">Activo</p>
        </div>
      </div>

      <!-- QR Code Section -->
      <div class="bg-white p-4 rounded-2xl shadow-sm border border-gray-100">
        <qrcode
          *ngIf="state.currentData"
          [qrdata]="state.currentData?.user?.userID"
          [imageSrc]="'/assets/img/cdi-logo.png'"
          [allowEmptyString]="true"
          [imageHeight]="40"
          [imageWidth]="40"
          [width]="200"
          [margin]="0"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
      </div>

      <!-- Instructions -->
      <p class="text-sm text-gray-500 text-center">
        Presente este código QR en recepción para acceder
      </p>
    </div>
  </section>
</ng-container>
