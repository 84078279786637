import { NodeWithI18n } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { W } from '@fullcalendar/core/internal-common';
import { map } from 'rxjs';

import { Observable } from 'rxjs';
export interface WPPost {
  id: number;
  title: { rendered: string };
  excerpt: { rendered: string };
  link: string;
  date: string;
  new?: boolean;
  uagb_featured_image_src: { full: string[] };
  content: { rendered: string };
}

@Injectable({
  providedIn: 'root',
})
export class WpService {
  constructor(
    private angularFirestore: AngularFirestore,
    private angularfirefunctions: AngularFireFunctions
  ) {}

  /**
   * Gets the latest posts (default 3)
   */
  getPosts() {
    return this.angularFirestore
      .collection('settings/contactList/homePosts')
      .doc('list')
      .valueChanges()
      .pipe(map((doc: { lastUpdate: Date; posts: WPPost[] }) => doc.posts));
  }

  /**
   * Gets posts by tag name
   */
  getPostsByTag(tag: string) {
    const callable = this.angularfirefunctions.httpsCallable('getWpPosts');
    return callable({ tag }).pipe(map((res: any) => res)) as Observable<
      WPPost[]
    >;
  }

  getPostById(id: string) {
    const callable = this.angularfirefunctions.httpsCallable('getWpPosts');
    return callable({ id: id, tag: 'single' }).pipe(
      map((res: any) => res)
    ) as Observable<WPPost>;
  }
}
