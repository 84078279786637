<ng-container *ngIf="{screeSize: screeSize$ | async} as state" >

        <router-outlet></router-outlet>
  



    <ng-template #noDesktop>
        <div class="flex flex-col justify-center items-center h-full w-2/3 mx-auto text-center">
            <img src="/assets/img/cdi-logo.png">
            <p>La vista para escritorio esta en construccion, por favor entrar usando un dispositivo movil.</p>
        </div>
    </ng-template>
</ng-container>

