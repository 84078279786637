import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, shareReplay, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface WPPost {
  title: { rendered: string };
  excerpt: { rendered: string };
  link: string;
  date: string;
  new?: boolean;
  uagb_featured_image_src: { full: string[] };
}

interface CacheData {
  timestamp: number;
  posts: WPPost[];
}

@Injectable({
  providedIn: 'root'
})
export class WpService {
  private readonly API_URL = 'https://casaditalia.com.ve/wp-json/wp/v2/posts?tags=7,8&per_page=3';
  private readonly CACHE_KEY = 'wp_posts_cache';
  private readonly CACHE_DURATION = 5 * 60 * 1000; // 5 minutos

  private postsCache$ = new BehaviorSubject<WPPost[]>([]);

  constructor(private http: HttpClient) {
    this.initializeCache();
  }

  getPosts(): Observable<WPPost[]> {
    const cachedData = this.getCachedData();

    if (cachedData && this.isCacheValid(cachedData.timestamp)) {
      return of(this.getLatestPosts(cachedData.posts));
    }

    return this.http.get<WPPost[]>(this.API_URL).pipe(
      tap(posts => this.updateCache(
        this.getLatestPosts(posts)
      )),
      catchError(error => {
        console.error('Error fetching posts:', error);
        const expiredCache = this.getCachedData();
        return of(expiredCache?.posts || []);
      }),
      shareReplay(1)
    );
  }

  clearCache(): void {
    localStorage.removeItem(this.CACHE_KEY);
    this.postsCache$.next([]);
  }

  private initializeCache(): void {
    const cachedData = this.getCachedData();
    if (cachedData) {
      this.postsCache$.next(cachedData.posts);
    }
  }

  private updateCache(posts: WPPost[]): void {
    const cacheData: CacheData = {
      timestamp: Date.now(),
      posts
    };

    localStorage.setItem(this.CACHE_KEY, JSON.stringify(cacheData));
    this.postsCache$.next(posts);
  }

  private getCachedData(): CacheData | null {
    try {
      const cached = localStorage.getItem(this.CACHE_KEY);
      return cached ? JSON.parse(cached) : null;
    } catch (error) {
      console.error('Error reading cache:', error);
      return null;
    }
  }

  private isCacheValid(timestamp: number): boolean {
    return Date.now() - timestamp < this.CACHE_DURATION;
  }

  private getLatestPosts(posts: WPPost[]): WPPost[] {
    const [first, second, third] = posts
      .sort((a, b) => {
        const dateA = new Date(a?.date).getTime();
        const dateB = new Date(b?.date).getTime();

        return dateB - dateA;
      })

    const structuredOrder = [second, { ...first, new: true }, third];
    console.log(structuredOrder);
    return structuredOrder;
  }
}
