import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private angularFireStore: AngularFirestore) { }

  getSettings() {
    return this.angularFireStore.collection('settings').valueChanges()
  }

  getBookingRules() {
    return this.angularFireStore.collection<any>('settings')
      .doc('bookingRules').valueChanges()
  }

  getConstants() {
    return this.angularFireStore.collection('settings').doc('constants').valueChanges() as Observable<any>
  }
  getDepartments() {
    return this.angularFireStore.collection('settings').doc('departments').valueChanges() as Observable<any>
  }
  getHomeInfo() {
    return this.angularFireStore.collection<any>('settings')
      .doc('contactList').valueChanges()
  }
  getQrSettings() {
    return this.angularFireStore.collection<any>('settings')
      .doc('qrSettings').valueChanges()
  }

  getGameModes(){
    return this.angularFireStore.collection<any>('settings')
      .doc('gameModes').valueChanges()
  }


}
