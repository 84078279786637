import { Component, OnInit } from '@angular/core';
import { TranslateLangService } from './shared/services/service.index';
import { SettingsService } from './shared/services/settings.service';
import { PushNotificationService } from './shared/services/push-notifications.service';
import { ScreenSizeService } from './shared/services/screen-service.service';
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { WpService } from './shared/services/wp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'shareholderapp-cdi';

  screeSize$ = this.screeSize.resize$;
  constructor(
    private screeSize: ScreenSizeService,

    private wpService: WpService  
  ) {


  }

  ngOnInit(): void {}
}
